<template>
  <b-overlay
      :show="loading"
      rounded="lg"
      opacity="0.6"
      class="w-100 mt-1"
  >
    <PageHeader screenName="Grupo de Empresas" :linkItems="linkItems" />
    <div v-if="grupo != null">
      <main class="card hidden_overflow_tabela">
        <b-row class="m-1 d-flex">
          <b-col class="mt-2">
            <h2 class="text-custom-blue">
              Grupo: {{ grupo.descricao }}
            </h2>
          </b-col>
          <b-col
              lg="2"
              class="mt-2 button-edit"
          >
            <b-button
                class="ml-auto"
                variant="primary-button"
                @click="redirectToEdit(grupo.id_grupo)"
            >
              <feather-icon
                  icon="EditIcon"
                  class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col lg="12">
            <h5 class="font-weight-bold mb-2">
              Coordenação do Grupo:
            </h5>
          </b-col>
          <b-col lg="4">
            <span class="font-weight-bold">Administrativo e financeiro:</span><br>
            {{ grupo.departamento_administrativo ? grupo.departamento_administrativo.descricao : '' }}
          </b-col>
          <b-col lg="4">
            <span class="font-weight-bold">Relacionamento:</span><br>
            {{ grupo.departamento_relacionamento ? grupo.departamento_relacionamento.descricao : '' }}
          </b-col>
          <b-col lg="4">
            <span class="font-weight-bold">Técnico:</span><br>
            {{ grupo.departamento_tecnico ? grupo.departamento_tecnico.descricao : '' }}
          </b-col>
        </b-row>
        <div
            v-if="loadingData"
            class="d-flex justify-content-center my-3"
        >
          <div
              class="spinner-border text-custom-blue"
              role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <FilterCompanyList
            :filter="filter"
            :group="grupo"
            :leftButtons="true"
            :backgroundColor="true"
            :errorApi="errorApiSearchParameters"
            @filterTable="filterTableGroupCompany"
        />
        <b-tabs
            active-nav-item-class="font-weight-bold text-white btn-primary-button"
            class="my-2"
            content-class="mt-2 bg-white"
            pills
            align="center"
        >
          <b-tab
              title="Empresas"
              class="p-0"
              active
          >
            <div v-if="grupo != null">
              <CompanyList
                  :group="grupo"
                  :clear="clear"
                  :filterProp="filter"
              />
            </div>
          </b-tab>
          <b-tab title="Documentação">
            <div v-if="grupo != null">
              <DocumentList
                  :group="grupo"
              />
            </div>
          </b-tab>
        </b-tabs>

      </main>
    </div>
  </b-overlay>
</template>

<script>
import {
  BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { setIdCompanyGroup } from '@core/utils/store/setStore'
import { getIdCompanyGroup } from '@core/utils/store/getStore'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import { actions, subjects } from '@/libs/acl/rules'
import CompanyList from '@/views/custom-pages/GrupoEmpresa/ListViewGroupCompany/CompanyList.vue'
import DocumentList from '@/views/custom-pages/GrupoEmpresa/ListViewGroupCompany/DocumentList.vue'
import FilterCompanyList from '@/views/custom-pages/GrupoEmpresa/Components/FilterCompanyList.vue'
import FilterGroupList from '@/views/custom-pages/GrupoEmpresa/Components/FilterGroupList.vue'
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";

export default {
  title: 'Ver grupo de empresas',

  components: {
    PageHeader,
    FilterGroupList,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    CustomPagination,
    BTab,
    BTabs,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    TableFilter,
    CompanyList,
    DocumentList,
    FilterCompanyList,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Listagem de grupos',
          routeName: 'group-company-list'
        },
        {
          name: 'Visualizar',
          routeName: '',
          active: true,
        },
      ],
      loading: false,
      loadingData: false,
      filtering: false,
      clear: false,
      errorApiSearchParameters: {
        identificador_empresa: null,
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tableDocument: {
        fields: [
          {
            key: 'documento', label: 'DOCUMENTAÇÃO E CONTRATOS', sortable: false, class: 'width_columns width_columns_header width_columns_header_company',
          },
          {
            key: 'criacao', label: 'ENVIADO EM', sortable: false, class: 'width_columns width_columns_header',
          },
          {
            key: 'usuario_criador', label: 'USUÁRIO', sortable: false, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'acoes', label: 'AÇÕES', sortable: false, class: 'width_columns_small width_columns_header',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_grupo_documento',
        order: 'asc',
      },
      withoutCompany: false,
      withoutDocument: false,
      lengthColumnMaximo: 28,
      grupo: null,
      filter: false,
    }
  },
  async mounted() {
    this.loading = true
    const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdCompanyGroup(), this.$route.params.idGrupo)
    if (hasIdEntityRouteParam) {
      setIdCompanyGroup(this.$route.params.idGrupo)
    }

    await this.loadInfoGroup(getIdCompanyGroup())

    this.verifyPermission()
    this.loading = false
  },

  methods: {
    filterTableGroupCompany(filter) {
      this.loadInfoGroup(getIdCompanyGroup(), filter)
    },
    async loadInfoGroup(idGrupo, filter = false) {
      this.errorApiSearchParameters.identificador_empresa = null
      this.filter = filter.filter
      this.loading = true
      const parameters = {
        nome_empresa: filter.nome_fantasia ?? null,
        identificador_empresa: filter.identificador_empresa ?? null,
        operador: filter.operador ?? null,
        status: filter.status ?? null,
        pagina: filter.pagina ? filter.pagina : this.paginationData.currentPage,
      }

      if (filter.clear) {
        this.clear = filter.clear
        this.filter = filter.filter
      }

      await this.$http.get(this.$api.getAndPutAndDeleteGrupo(idGrupo), { params: parameters }).then(({ data }) => {
        this.tableDocument.items = data.documento
        this.grupo = data
        this.withoutCompany = false
        this.withoutDocument = false

        if (data) {
          if (Object.keys(data).length !== 0) {
            if (data.empresa_operador.lenght === 0) {
              this.withoutCompany = true
            }

            if (data.documento.length == 0) {
              this.withoutDocument = true
            }
          }
        }
      }).catch(({ response: { data } }) => {
        const error = data.errors
        this.errorApiSearchParameters.identificador_empresa = error.identificador_empresa ? error.identificador_empresa[0] : ''
        this.filtering = false
      })
      this.loading = false
    },

    redirectToEdit(id_grupo) {
      this.$router.push(
          {
            name: 'edit-company-group',
            params: {
              idGrupo: id_grupo,
            },
          },
      )
    },

    verifyPermission() {
      return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
    },
  },
  updated() {
    this.clear = false
  },
}
</script>

<style lang="scss">

.table-company {
  background-color: #fff;
}

.button-edit {
  text-align: end;
}
</style>
