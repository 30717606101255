<template>
  <section>
    <b-table 
      id="tableEmpresa" 
      class="table-company" 
      responsive 
      :stickyColumn="true" 
      :busy.sync="filtering"
      :fields="tableCompany.fields" 
      :items="tableCompany.items" 
      no-local-sorting :no-sort-reset="true"
      @sort-changed="orderColumn"
    >
      <template #cell(nome_cidade)="row">
        <div class="comprimento_maximo_celulas">
          {{row.item.grupo_operadores[0].nome_cidade ? row.item.grupo_operadores[0].nome_cidade : ''}}
        </div>
      </template>
      <template #cell(documento)="row">
          <div class="comprimento_maximo_celulas">
            <span class="font-weight-bold comprimento_maximo_celulas"
                v-if="row.item.documento.length == 11"
                v-b-tooltip
                :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('###.###.###-##') }}
              </span>
              <span class="font-weight-bold comprimento_maximo_celulas"
                  v-else
                  v-b-tooltip
                  :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('##.###.###/####-##') }}
              </span>
          </div>
        </template>
      <template #cell(uf_cidade)="row">
        <div class="comprimento_maximo_celulas">
          {{ row.item.grupo_operadores[0].uf ? row.item.grupo_operadores[0].uf : '-' }}
        </div>
      </template>

      <template #cell(situacao_cadastro)="row">
        <RowSituacaoCadastro
            :length-column-maximo="lengthColumnMaximo"
            :situacao-cadastro="row.item.situacao_cadastro"
            classe="comprimento_maximo_celulas"
        />
      </template>
      <template #cell(acoes)="row">
        <feather-icon 
        @click="redirectToViewCompany(row.item.id_empresa)"
        id="view-empresa" 
        role="button" 
        class="text-custom-blue mr-1" 
        icon="EyeIcon" size="18" />
      </template>
    </b-table>
    <div class="pb-2 px-1">
      <span v-if="withoutCompany" class="ml-1">Nenhuma empresa encontrada</span>
      <b-col sm="12" class="mt-2">
        <CustomPagination :paginacao="paginationDataCompany" @page-cliked="updateCurrentPage" />
      </b-col>
    </div>
  </section>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BTab, BTabs } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import RowSituacaoCadastro from "@/views/components/custom/empresa/RowSituacaoCadastro.vue";

export default {
  props: {
    group: {
      required: true
    },
    clear: {
      required: false,
      type: Boolean,
      default: false,
    },
    filterProp: {
      required: false,
      type: Boolean,
      default: false,
    }
  },

  components: {
    RowSituacaoCadastro,
    BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
    CustomPagination, BTab, BTabs, TableFilter
  },

  data() {
    return {
      filtering: false,
      filter: false,
      loadingData: false,
      paginationDataCompany: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tableCompany: {
        fields: [
          { key: "nome_empresa", label: "NOME FANTASIA", sortable: true, class: 'width_columns width_columns_header width_columns_header_company' },
          { key: "documento", label: "CNPJ | CPF", sortable: true, class: 'width_columns width_columns_header' },
          { key: "nome_cidade", label: "CIDADE", sortable: false, class: 'width_columns_small width_columns_header' },
          { key: "uf_cidade", label: "UF", sortable: false, class: 'width_columns width_columns_header' },
          { key: "situacao_cadastro", label: "STATUS", sortable: false, class: 'width_columns width_columns_header' },
          { key: "acoes", label: "AÇÕES", sortable: false, class: 'width_columns_small width_columns_header' }
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'nome_empresa',
        order: 'asc'
      },
      withoutCompany: false,
      lengthColumnMaximo: 28,
    }
  },

  mounted() {
    this.tableCompany.items = this.group.operador
    this.loadScheduleData()
  },

  methods: {
    redirectToViewCompany(id_empresa) {
            this.$router.push(
                {
                    name: 'empresa-show',
                    params: {
                        idEmpresa: id_empresa
                    }
                }
            );
        },
    updateCurrentPage(page) {
      this.paginationDataCompany.currentPage = page;
      const indexArrayCompanies = page - 1
      this.loadScheduleData(indexArrayCompanies)
    },

    async loadScheduleData(index = this.paginationDataCompany.currentPage - 1, order = false, sortOptions = {}) {
      this.paginationDataCompany.defaultSize = 10

      let companies = this.splitOnPage(this.group.empresa_operador, 10)

      if(companies.length == 1) {
        index = 0
      }

      if(this.filterProp && this.paginationDataCompany.currentPage == 1) {
        index = 0
      }
      
      if(order) {
        companies = this.order(companies, sortOptions, index)
      }

      this.tableCompany.items = companies[index]
      this.withoutCompany = false;

      if (this.group.operador == null) {
        this.withoutCompany = true
      }

      this.startPagination(companies[index], index)
    },

    startPagination(companies, fromLine) {
      this.paginationDataCompany.totalLines = this.getTotalLines()
      this.paginationDataCompany.fromLine = this.getFromLine(fromLine, companies)
      this.paginationDataCompany.toLine = this.getToLine(companies, fromLine)
    },

    getTotalLines() {
      if (this.group.empresa_operador == null) {
        return 0
      }
      
      return this.group.empresa_operador.length
    },

    getFromLine(fromLine, companies) {
      if (companies) {
        return fromLine == 0 ? '1' : String(fromLine) + '1';
      }

      return fromLine == 0 ? '0' : String(fromLine) + '1';
    },

    getToLine(companies, fromLine) {
      const totalCompanies = companies != null ? companies.length : 0

      if (fromLine == 0) {
        return String(totalCompanies)
      }

      if (totalCompanies == 10) {
        return String(1 + fromLine) + '0'
      }

      return String(fromLine) + String(totalCompanies)

    },

    calculateTotalLines(companies) {
      let totalLines = 0

      if (companies == null) {
        return totalLines
      }

      totalLines = Math.ceil(companies.length / 10)

      return totalLines
    },

    splitOnPage(companies, itemPerPage) {
      if (companies == null) {
        return 1;
      }

      const pages = [];
      for (let i = 0; i < companies.length; i += itemPerPage) {
        pages.push(companies.slice(i, i + itemPerPage));
      }
      return pages;
    },

    orderColumn(sort) {
      this.tableCompany.columnOrder = sort.sortBy;
      this.tableCompany.order = sort.sortDesc ? 'desc' : 'asc';
      const sortOptions = {
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc
      }

      this.loadScheduleData(this.paginationDataCompany.currentPage - 1, true, sortOptions);
    },

    order(companies, sortOptions, index) {
      if (sortOptions.sortDesc) {
        companies[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'documento') {
            return b[sortOptions.sortBy] - a[sortOptions.sortBy]
          }
          return b[sortOptions.sortBy].localeCompare(a[sortOptions.sortBy]);
        });
      }

      if (!sortOptions.sortDesc) {
        companies[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'documento') {
            return a[sortOptions.sortBy] - b[sortOptions.sortBy]
          }
          return a[sortOptions.sortBy].localeCompare(b[sortOptions.sortBy]);
        });
      }

      return companies
    },

    parseDate(date) {
      const dateSplit = date.split('-')

      return new Date('20' + dateSplit[2].substring(0,2), dateSplit[1] - 1, dateSplit[0])
    },

  },
  watch: {
    group: {
      handler(newVal) {
        if (newVal ) {
          if (!Object.hasOwnProperty(newVal.operador)) {
            this.tableCompany.items = [];
            this.withoutCompany = true;
            this.loadScheduleData()
          }
        }
      },
      deep: true,
      immediate: true,
    },
    filterProp() {
      this.paginationDataCompany.currentPage = 1
    },
    clear: {
      handler(value) {
        if (value) {
          this.paginationDataCompany.currentPage = 1
        }
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>